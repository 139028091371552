// Generated by Framer (c60b0a0)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {INS9rFpUY: {hover: true}};

const cycleOrder = ["INS9rFpUY"];

const serializationHash = "framer-nuVee"

const variantClassNames = {INS9rFpUY: "framer-v-1cxe951"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "INS9rFpUY", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1cxe951", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"INS9rFpUY"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"INS9rFpUY-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><SVG className={"framer-ktkhzv"} data-framer-name={"Frame_3"} fill={"black"} intrinsicHeight={31} intrinsicWidth={31} layoutDependency={layoutDependency} layoutId={"T8_HTvqex"} svg={"<svg width=\"31\" height=\"31\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M.107 2.052C-.37.832.833-.371 2.052.107l27.54 10.81c1.266.498 1.27 2.289.005 2.791L18.873 17.97a1.5 1.5 0 0 0-.838.837L13.71 29.612c-.504 1.262-2.292 1.256-2.789-.009L.107 2.052Z\" fill=\"#D9D9D9\"/></svg>"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-nuVee.framer-g4ticn, .framer-nuVee .framer-g4ticn { display: block; }", ".framer-nuVee.framer-1cxe951 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; padding: 0px; position: relative; width: min-content; }", ".framer-nuVee .framer-ktkhzv { flex: none; height: 16px; position: relative; width: 16px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-nuVee.framer-1cxe951 { gap: 0px; } .framer-nuVee.framer-1cxe951 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-nuVee.framer-1cxe951 > :first-child { margin-left: 0px; } .framer-nuVee.framer-1cxe951 > :last-child { margin-right: 0px; } }", ".framer-nuVee.framer-v-1cxe951.hover .framer-ktkhzv { height: 12px; width: 12px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 16
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"X2jlDSf9I":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerEH8_URL8x: React.ComponentType<Props> = withCSS(Component, css, "framer-nuVee") as typeof Component;
export default FramerEH8_URL8x;

FramerEH8_URL8x.displayName = "cursor";

FramerEH8_URL8x.defaultProps = {height: 16, width: 16};

addFonts(FramerEH8_URL8x, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})